/* eslint react/prop-types: 0 */
import React from "react";
import { navigate } from "gatsby";

import { translateStatic } from "../helpers/translationHelper";

class Buildings extends React.Component {
  constructor(props) {
    super(props);

    const { allBuildings, pageContext } = props;

    const {
      buildings,
      paginatorInfo,
      prefix,
      locale,
    } = pageContext;

    this.state = {
      searchedBuildings: allBuildings,
      buildings: buildings,
      paginatorInfo: paginatorInfo,
      navigationPrefix: `${locale}/${prefix}`,
      sortOptions: {},
      sort: "",
      latitude: null,
      longitude: null,
      zoom: 12
    };
  }

  componentDidMount() {
    let { search } = this.props.location;
    let params = new URLSearchParams(search);
    let sortColumn = params.get("sort_column");
    let sortOrder = params.get("sort_order");
    this.setState({ sort: `${sortColumn}-${sortOrder}` });

    this.initializeSortOptions();
  }

  initializeSortOptions() {
    let {
      currentLocaleKey: locale,
      defaultLocaleKey: defaultLocale,
    } = this.props.result;
    let asc = translateStatic("asc", locale, defaultLocale);
    let desc = translateStatic("desc", locale, defaultLocale);
    let columns = ["created_at", "updated_at"];
    let sortOptions = {};
    for (let column of columns) {
      let columnName = translateStatic(column, locale, defaultLocale);
      sortOptions[column + "-asc"] = `${columnName} (${asc})`;
      sortOptions[column + "-desc"] = `${columnName} (${desc})`;
    }
    this.setState({ sortOptions });
  }

  handleSortChange = (event) => {
    const { value } = event.target;
    const { search } = this.props.location;
    let params = new URLSearchParams(search);
    if (value) {
      const [column, order] = value.split("-");
      params.set("sort_column", column);
      params.set("sort_order", order);
      navigate(`/${this.state.navigationPrefix}?${params.toString()}`);
    } else {
      params.delete("sort_column");
      params.delete("sort_order");
      navigate(`/${this.state.navigationPrefix}?${params.toString()}`);
    }
  };

  setBuildings = (searchedBuildings, paginatedData) => {
    this.setState((state) => ({
      searchedBuildings: searchedBuildings,
      buildings: paginatedData.data,
      paginatorInfo: paginatedData.paginatorInfo,
    }));
  };

  setMapOptions = (latitude, longitude, zoom) => {
    if (zoom) {
      if (!isNaN(zoom)) this.setState({ zoom: parseInt(zoom) });
    }
    this.setState({ latitude, longitude });
  }

}

export default Buildings;
