/* eslint react/prop-types: 0 */
import React from "react";
import { navigate } from "gatsby";
import _ from "lodash";

import { translateStatic } from "../helpers/translationHelper";
import { createPaginatedData, itemsPerPage } from "../helpers/createPage";
import { compareObjects } from "../helpers/helper";

class SearchBuildings extends React.Component {
  constructor(props) {
    super(props);

    const { locale, defaultLocale } = props;

    this.state = {
      searchForm: {
        search: "",
        city: "",
        district: "",
        state: "",
        country: "",
        tag: "",
        type: "",
        sort_column: "",
        sort_order: "",
      },
      textSearchOptions: {
        search: translateStatic("all", locale, defaultLocale),
        city: translateStatic("city", locale, defaultLocale),
        district: translateStatic("district", locale, defaultLocale),
        state: translateStatic("state", locale, defaultLocale),
        country: translateStatic("country", locale, defaultLocale),
        tag: translateStatic("tag", locale, defaultLocale)
      },
      textSearchKey: "search",
    };
  }

  componentDidMount() {
    this.filterBuildings();
  }

  handleInputChange = (event) => {
    const { name, value } = event.target;
    const { searchForm } = this.state;
    searchForm[name] = value;
    this.setState({
      searchForm: searchForm,
    });
  };

  handleTextSearchKeyChange = (event) => {
    const { value } = event.target;
    this.setState({ textSearchKey: value });
  };

  handleTextSearchValueChange = (event) => {
    const { value } = event.target;
    const { searchForm } = this.state;
    searchForm[this.state.textSearchKey] = value;
    this.setState({ searchForm })
  };

  handleSortChange = (event) => {
    const { value } = event.target;
    const { search } = this.props.location;
    let params = new URLSearchParams(search);
    if (value) {
      const [column, order] = value.split("-");
      params.set("sort_column", column);
      params.set("sort_order", order);
      navigate(`/${this.state.navigationPrefix}?${params.toString()}`);
    } else {
      params.delete("sort_column");
      params.delete("sort_order");
      navigate(`/${this.state.navigationPrefix}?${params.toString()}`);
    }
  };

  clearSearchForm() {
    navigate(`/${this.props.navigationPrefix}`);
  }

  searchBuildings() {
    const { search } = this.props.location;
    let searchParams = Object.fromEntries(new URLSearchParams(search));
    searchParams = _.omit(searchParams, ['latitude', 'longitude', 'swl_lng', 'sw_lat', 'ne_lng', 'ne_lat', 'zoom']);
    if (!compareObjects(searchParams, this.state.searchForm)) {
      if (Object.values(this.state.searchForm).filter((val) => Boolean(val)).length == 0) {
        this.clearSearchForm();
      } else {
        let searchFormData = Object.fromEntries(Object.entries(this.state.searchForm).filter(v => v[1]));
        let queryString = new URLSearchParams(searchFormData).toString();
        navigate(`/${this.props.navigationPrefix}?${queryString}`);
      }
    }
  }

  filterBuildings() {
    let { buildings, locale, location, navigationPrefix } = this.props;
    let { search, pathname } = location;

    let searchObj = Object.fromEntries(new URLSearchParams(search));

    for (var i in this.state.textSearchOptions) {
      if (searchObj[i]) {
        this.setState({ textSearchKey: i });
        break;
      }
    }

    let searchForm = {};
    let searched = false;

    if (searchObj.search) {
      let searchInput = searchObj.search.toLowerCase();
      buildings = buildings.filter((building) => {
        if (
          building.name &&
          building.name.toLowerCase().indexOf(searchInput) !== -1
        ) {
          return true;
        }

        if (
          building.location &&
          building.location.toLowerCase().indexOf(searchInput) !== -1
        ) {
          return true;
        }

        if (
          building.country &&
          building.country.country_name &&
          building.country.country_name.toLowerCase().indexOf(searchInput) !== -1
        ) {
          return true;
        }

        let translation = _.find(building.translations, { locale: locale });
        if (
          translation &&
          translation.headline &&
          translation.headline.toLowerCase().indexOf(searchInput) !== -1
        ) {
          return true;
        }

        if (_.find(building.buildingTag, { name: searchInput })) {
          return true;
        }
      });
      searchForm.search = searchObj.search;
      searched = true;
    }

    if (searchObj.city) {
      let cityInput = searchObj.city.toLowerCase();
      buildings = buildings.filter(
        (building) =>
          building.city && building.city.toLowerCase().indexOf(cityInput) !== -1
      );
      searchForm.city = searchObj.city;
      searched = true;
    }

    if (searchObj.district) {
      let districtInput = searchObj.district.toLowerCase();
      buildings = buildings.filter(
        (building) =>
          building.district && building.district.toLowerCase().indexOf(districtInput) !== -1
      );
      searchForm.district = searchObj.district;
      searched = true;
    }

    if (searchObj.state) {
      let stateInput = searchObj.state.toLowerCase();
      buildings = buildings.filter(
        (building) =>
          building.state && building.state.toLowerCase().indexOf(stateInput) !== -1
      );
      searchForm.state = searchObj.state;
      searched = true;
    }

    if (searchObj.country) {
      let countryInput = searchObj.country.toLowerCase();
      buildings = buildings.filter(
        (building) => 
          building.country &&
          building.country.country_name &&
          building.country.country_name.toLowerCase().indexOf(countryInput) !== -1
      );
      searchForm.country = searchObj.country;
      searched = true;
    }

    if (searchObj.tag) {
      let tags = searchObj.tag.split(',');
      buildings = buildings.filter(building => {
        for (let tag of tags) {
          if (_.find(building.buildingTag, { name: tag })) {
            return true;
          }
        }
      });
      searchForm.tag = searchObj.tag;
      searched = true;
    }

    if (searchObj.type) {
      buildings = buildings.filter(
        (building) =>
          building.property_type && building.property_type.id == searchObj.type
      );
      searchForm.type = searchObj.type;
      searched = true;
    }

    let { center_lat: latitude, center_lng: longitude } = searchObj;
    const { sw_lng, sw_lat, ne_lng, ne_lat } = searchObj;
    if (latitude && longitude && sw_lng && sw_lat && ne_lng && ne_lat) {
      buildings = buildings.filter((building) => {
        return (
          (sw_lat < ne_lat && building.latitude >= sw_lat && building.latitude <= ne_lat) ||
          (sw_lat > ne_lat && building.latitude >= sw_lat && building.latitude <= ne_lat)
        );
      });

      buildings = buildings.filter((building) => {
        return (
          (sw_lng < ne_lng && building.longitude >= sw_lng && building.longitude <= ne_lng) ||
          (sw_lng > ne_lng && building.longitude >= sw_lng && building.longitude <= ne_lng)
        );
      });

      searched = true;
    }

    if (searchObj.sort_column && searchObj.sort_order) {
      let sortColumn = searchObj.sort_column;
      let sortOrder = searchObj.sort_order;
      if (sortColumn == "created_at" || sortColumn == "updated_at") {
        buildings.sort(function compare(building1, building2) {
          let date1 = new Date(building1[sortColumn]);
          let date2 = new Date(building2[sortColumn]);
          return sortOrder == "asc" ? date1 - date2 : date2 - date1;
        });
      } else {
        buildings = _.orderBy(
          buildings,
          [(el) => el[sortColumn] || ""],
          [sortOrder]
        );
      }
      searchForm.sort_column = sortColumn;
      searchForm.sort_order = sortOrder;
      searched = true;
    }

    let paginatedData = null;
    if (searched) {
      let page = pathname.replace(`/${navigationPrefix}`, "").replace("/", "");
      paginatedData = createPaginatedData(
        buildings,
        itemsPerPage,
        page ? parseInt(page) : 1
      );

      this.props.setBuildings(buildings, paginatedData);

      this.setState({
        searchForm: { ...this.state.searchForm, ...searchForm },
      });
    }

    if (!latitude || !latitude) {
      if (paginatedData) {
        const building = paginatedData.data.find(el => el.latitude && el.longitude);
        latitude = building?.latitude;
        longitude = building?.longitude;
      } else {
        const building = buildings.find(el => el.latitude && el.longitude);
        latitude = building?.latitude;
        longitude = building?.longitude;
      }
    }

    if (this.props.setMapOptions) {
      this.props.setMapOptions(latitude, longitude, searchObj.zoom)
    }
  }
  
}

export default SearchBuildings;
