/* eslint react/prop-types: 0 */
import React, { Fragment } from "react";

import SearchBuildings from "../../../common/SearchBuildings";
import {
  getTranslatedValue,
  translateStatic,
} from "../../../helpers/translationHelper";
import * as theme2Styles from "../scss/theme2.module.scss";

class BuildingSearch extends SearchBuildings {
  constructor(props) {
    super(props);
  }

  render() {
    const { propertyTypes, locale, defaultLocale } = this.props;

    return (
      <Fragment>
        <div className={`${theme2Styles.searchColm}`}>
          <div className={theme2Styles.filterSearch}>
            <div className={theme2Styles.inlineField}>
              <input 
                type="text"
                className={theme2Styles.field}
                value={this.state.searchForm[this.state.textSearchKey]}
                onChange={this.handleTextSearchValueChange}
              />              
              <select 
                type="text"
                className={`${theme2Styles.field} ${theme2Styles.selField}`}
                value={this.state.textSearchKey}
                onChange={this.handleTextSearchKeyChange}
              >
                {Object.keys(this.state.textSearchOptions).map(key => (
                  <option key={key} value={key}>{this.state.textSearchOptions[key]}</option>
                ))}
              </select>
            </div>
          </div>
        </div>
            {propertyTypes.length > 0 && <div className={theme2Styles.colm}>
              <select
                className={theme2Styles.field}
                name="type"
                value={this.state.searchForm.type}
                onChange={this.handleInputChange}
              >
                <option value="">
                  {translateStatic("type", locale, defaultLocale)}
                </option>
                {(() => {
                  return propertyTypes.map((property_type) => {
                    return (
                      <option value={property_type.id} key={property_type.id}>
                        {getTranslatedValue(
                          property_type.translations,
                          "name",
                          locale,
                          defaultLocale
                        )}
                      </option>
                    );
                  });
                })()}
              </select>
            </div>}
          <div className={`${theme2Styles.filterBtns}`}>
            <button
              className={`${theme2Styles.btn} ${theme2Styles.btnSm} ${theme2Styles.propSrchBtn}`}
              onClick={() => this.searchBuildings()}
            >
              {translateStatic("filter", locale, defaultLocale)}
            </button>
            <button
              className={`${theme2Styles.btn} ${theme2Styles.btnSm}`}
              onClick={() => this.clearSearchForm()}
            >
              {translateStatic("clear", locale, defaultLocale)}
            </button>
          </div>
      </Fragment>
    );
  }
}

export default BuildingSearch;
